<template>
    <div>


        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col col-8">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Základné ceny</h3></div>
                            </div>
                        </div>
                        <form @submit.prevent="handleSubmit">
                            <div class="row image-row" v-for="room in data">
                                <div class="pl-lg-5 head" ><h4 :style="'color:' + room.color.hex">{{room.title}}</h4>
                                    <img v-if="JSON.parse(room.image).uri" :src="url.IMG_URL + 'room' + JSON.parse(room.image).uri" class="room-image">
                                    <sidebar-item class="btn nopad mt-1 text-default btn-icon btn-secondary btn-sm" :link="{name: 'Podrobný rozpis', icon: 'fas fa-money-bill-wave-alt', path: '/roomprice/' + room.id}"/>
                                </div>
                                <span class="params" v-if="room.object !== true">
<compact-picker v-model="room.color"   :palette="['#ee4035', '#f37736', '#7bc043', '#0392cf', '#96ceb4', '#ff6f69','#ffcc5c', '#88d8b0','#76b4bd','#58668b','#e0ac69','#3b7dd8', '#eabaac', '#be3400', '#020d22', '#015045', '#f3a001', '#ccccf4', '#0077c5', '#313853','#fdf2b8', '#e88200', '#cb2800', '#34262b','#935C61','#704754','#6B94AA','#87B6BB','#CCC4AE','#6B90C0','#395FAC','#28252C','#853728','#AB5465','#193233','#617A78','#ABB2B3','#F0B0F0','#D01F09']" ></compact-picker>
                         <div class="p" style="margin-top:10px;">
                             <div class="par" v-if="room.beds > 0" v-for="index in parseInt(room.beds)" >
                            <a-input alternative="" :label="index + 'x dospelý'" :key="room.id+'_s_'+index" input-classes="form-control-alternative" v-model="prices[room.id+'_s_'+index]"/>
                        </div>
                             <span v-if="room.extrabed > 0" >
                            <label class="form-control-label">

                                </label>   <span class="form-control form-control-alternative" style="background-color:transparent;line-height:50px;padding-left:15px;"> + {{room.extrabed}} prístelky</span>
                             </span>
                         </div>


                     </span>
                                <span class="params"   v-else >
                             <a-input alternative="" style="float:left;margin:0.5%;" class="par" label="Cena za objekt" input-classes="form-control-alternative" v-model="prices[room.id+'_s_0']"/>
                           </span>




                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary">Uložiť</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-4">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h5 class="mb-10 text-gray">Prístelky na hoteli</h5>
                                </div>
                                <div class="col-4 text-right">
                                    <a-button type="primary" @click="addExtrabedModal = true">Pridať</a-button>
                                </div>
                            </div>
                        </div>
                        <template>
                            <a-table
                                    :columns="columns"
                                    :rowKey="record => record.id"
                                    :dataSource="extrabedprices"
                                    :loading="loading"
                                    class="table-flush">
                                <template slot="contenttype" slot-scope="ct">
                                    {{fetchcttitle(ct)}}
                                </template>
                                <template slot="price" slot-scope="price">
                                    <span v-if="price === 0">Zadarmo</span><span v-else>{{price}} €</span>
                                </template>
                                <template slot="ageto" slot-scope="age">
                                   {{age - 0.01}}
                                </template>

                                <template slot="name" slot-scope="user">
                                    <a-button @click="addService" key="edit" icon="edit" v-if="user.id" type="primary"></a-button>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(user.id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="secondary"  icon="delete"></a-button>
                                    </a-popconfirm>
                                </template>
                            </a-table>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>

    <a-modal :title="'Nová prístelka'" centered  v-model="addExtrabedModal" v-if="addExtrabedModal" @ok="addExtrabed">
        <div class="additional-setup">
            <div>
                <div class="mb-2">
                    <label class="form-control-label">Vek od</label>
                    <a-select style="width: 100%"
                              v-model="newExtra.agefrom"
                              placeholder="Vyberte si zo zoznamu">
                        <a-select-option :key="0">0</a-select-option>
                        <a-select-option v-for="op in 99" :key="op">{{op}}</a-select-option>
                    </a-select>
                </div>

                <div class="mb-2">
                    <label class="form-control-label">Vek do</label>
                    <a-select style="width: 100%"
                              v-model="newExtra.ageto"
                              placeholder="Vyberte si zo zoznamu">
                        <a-select-option v-for="op in 99" :key="op">{{op}}</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="mb-2">
                <label class="form-control-label">Cena</label>
                <a-input-number :step="0.1" v-model="newExtra.price" style="width: 100%" />
            </div>
        </div>
    </a-modal>

    </div>

</template>

<script>

    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import {Popconfirm,Select} from "ant-design-vue"
    import { Compact } from 'vue-color'

    Vue.use(FormItem);

    export default {
        name: "pricerooms",
        components: {
            "a-popconfirm": Popconfirm,
            "a-select" : Select,
            'compact-picker': Compact,
        },
        data() {
            return {
                url: getURL(),
                data: "",
                colorsto: JSON.parse(localStorage.colors),
                colors: {},
                prices: {},
                addExtrabedModal: false,
                extrabedprices:{},
                newExtra: {agefrom:1,ageto:12,price:""},
                ages: [
                    {"value": '1', "label": '1'},
                    {"value": '2', "label": '2'},
                    {"value": '3', "label": '3'},
                    {"value": '4', "label": '4'},
                    {"value": '5', "label": '5'},
                    {"value": '6', "label": '6'},
                    {"value": '7', "label": '7'},
                    {"value": '8', "label": '8'},
                    {"value": '9', "label": '9'},
                    {"value": '10', "label": '10'},
                    {"value": '11', "label": '11'},
                    {"value": '12', "label": '12'},
                    {"value": '13', "label": '13'},
                    {"value": '14', "label": '14'},
                    {"value": '15', "label": '15'},
                    {"value": '16', "label": '16'},
                    {"value": '17', "label": '17'},
                    {"value": '18', "label": '18'},
                ],
            }
        },
        computed: {
            columns() {
                let { sortedInfo, filteredInfo } = this;
                sortedInfo = sortedInfo || {};
                filteredInfo = filteredInfo || {};
                const columns = [
                    {
                        title: 'od',
                        dataIndex: 'agefrom'
                    },
                    {
                        title: 'do',
                        dataIndex: 'ageto',
                        scopedSlots: {customRender: 'ageto'},

                    },
                    {
                        title: 'Cena',
                        dataIndex: 'price',
                        scopedSlots: {customRender: 'price'}
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                        width: '140px'
                    }
                ];
                return columns;     },
        },
        methods: {
            handleOk(){

            },
            handleDates(list, prop) {
                return list.map(item => {
                    const obj = Object.assign({}, item);
                    obj[prop] = new Date(obj[prop]).toLocaleDateString();
                    return obj;
                });
            },
            addExtrabed(){
                dataService.axiosPost("addextrabed", this.newExtra);
            },
            handleSubmit() {
                let $user_data = this.prices;

                let $colordata = this.data;
                dataService.axiosPost("changecolors", $colordata);
                dataService.axiosPost(this.$options.name, $user_data);
            },

        },
        mounted() {
            dataService.axiosFetch('content','typy_izieb').then(results => {
                let roleops = {};
                results.forEach(obj => {

                    if(!obj.color)
                        obj.color = "#5da2ce";
                    roleops[obj.id] = obj;
                });
                this.data = roleops;
                this.loading = false;
            });

            dataService.axiosFetch('getroomprices').then(results => {

                let roomprices = results.roomprices;
                let roleops = {};
                roomprices.forEach(obj => { roleops[obj.room + "_" + obj.season + "_" +obj.pricetype] = obj.price; });
                this.prices = roleops;

                this.extrabedprices = results.extrabedprices;


            });


        }
    }
</script>

<style scoped>
    .image-row
    {min-height:100px; display:inline-flex;width:100%;margin:0;margin-top:0px; padding-bottom:20px; margin-bottom:15px;border-bottom:10px solid #f7f8fd;}
    .room-image
    { border-radius:5px;}
    .par{
        float:left;
        margin:0.5%;
        width:19%;
    }
    .vc-compact
    {width:100%}
    .head img
    {width:100%}
    h4.par{
        max-width: 200px;}
    .p
    {width:100%;display:inline-block;}
    .head .nav-item
    {width:100%;}
    .head img
    {width:100%}
    .head
    {width:20%;float:left;padding-right:2.5%;padding-left:1%!important;}
    .params
    {width:79%; padding:15px;float:left;background-color: #f5f5f5;}
</style>

